body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.landing-page {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 50px;
  background-color: #fc8d15;
  height: max-content;
  scroll-behavior: smooth;
}
.landing-page * {
  transition: all 0.3s ease;
}

.landing-page h1 {
  font-size: 2.5rem;
  color: black;
  font-family: monospace;
}

.landing-page p {
  font-size: 1.2rem;
  color: black;
  margin-bottom: 20px;
  font-weight: bold;
}

.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0;
}

.hero-content {
  flex: 1;
  padding-right: 50px; 
}

.hero-content h2 {
  font-size: 36px;
  margin-bottom: 40px;
  font-family: monospace;
  color: black;
}

.hero-content p {
  font-size: 18px;
  color: black;
  line-height: 1.5;
  padding-left: 20px;
}

.hero-image img {
  max-width: 100%;
  height: auto;
  border-radius: 20px;
  padding-right: 100px; 
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
  }
  .hero-content {
    padding-right: 0;
    margin-bottom: 30px;
  }
}
.popular-recipes-section {
  background-color: white;
}
.popular-recipes-section h2 {
  color: black;
  font-family: monospace;
  font-weight: 600;
  font-size: 30px;
  padding-top: 25px;
}

.recipee-cards {
  display: flex; 
  justify-content: space-between; 
  padding-left: 20px;
  padding-right: 20px;
}

.recipe-cards {
  width: calc(50% - 10px); 
  margin-right: 20px; 
  padding: 40px; 
  border: 1px solid transparent;
  border-radius: 5px; 
}

.recipe-cards:last-child {
  margin-right: 0; 
}

.recipe-cards img {
  max-width: 100%;
  height: auto;
  border-radius: 5px; 
}

.recipe-cards h3 {
  margin-top: 20px; 
  color: black;
  font-family: monospace;
}

.recipe-cards p {
  margin-bottom: 0;
  font-size: 14px;
  color: black;
}

.about-section {
  padding: 30px; 
  background-color: #222;
}

.about-section h2 {
  font-size: 24px; 
  color: white; 
  margin-bottom: 30px; 
  font-family: monospace;

}

.about-section p {
  font-size: 16px; 
  color: white; 
  line-height: 1.6; 
  margin-bottom: 45px; 
}

.about-section ul {
  list-style-type: none; 
  margin-left: 20px; 
}

.about-section ul li {
  font-size: 16px; 
  color: white; 
  margin-bottom: 10px;
  
}

.footer {
  background-color: white; 
  color: black; 
}

.footer-content {
  display: flex; 
  justify-content: space-between; 
  max-width: 1200px; 
  margin: 0 auto; 
}

.footer-section {
  flex: 1; 
  margin-right: 30px; 
}

.footer-section h3 {
  font-size: 20px; 
  margin-bottom: 20px; 
  font-family: monospace;
}

.footer-section ul {
  list-style-type: none;
  padding: 0; 
}

.footer-section ul li {
  font-size: 16px; 
  margin-bottom: 10px; 
}

.footer-bottom {
  background-color: #222; 
  padding: 20px; 
  text-align: center;
}

.footer-bottom p {
  margin: 0; 
  font-size: 14px; 
  color: white;
}


/*  NavBar Styling */

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.431);
}

nav ul li {
  margin: 0 10px;
  font-family: 'Times New Roman', Times, serif;
}
.navigation-link {
  text-decoration: none;
  color: #333;
  display: grid;
  font-weight: bold;
  padding: 8px 12px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #007bff;
  transition: background-color 0.3s ease;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 20px;
  padding: 10px;

}

nav ul li a:hover {
  background-color: #222;
  color: #fff;
  border-radius: 5px;
}

nav ul li a.active {
  color: #fff;
  background-color: #333;
}
.navigation-container {
  background-image: url(https://res.cloudinary.com/dswjeyq4u/image/upload/v1712507951/pexels-photo-616401_ebmqkr.jpg);
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
}

/* LoginForm.css */

.login-container {
  background-color: rgb(151, 151, 151); 
  padding: 40px; 
  height: 100vh;
}

.login-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: rgba(17, 24, 39, 1);
  height: 50vh;
}
.input-field {
  width: 90%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: rgba(17, 24, 39, 1);
}

.login-form h2 {
  margin-bottom: 20px;
  text-align: center;
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.login-form label {
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 15px
}
.error-message {
  color: red;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 600;
}
.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* SignUpForm.css */

.sign-form {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: rgba(17, 24, 39, 1);
  height: 50vh;
}

.sign-form h2 {
  margin-bottom: 20px;
  text-align: center;
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.sign-form label {
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 15px 
}
.error-message {
  color: red;
  margin-bottom: 10px;
}

.input-field {
  width: 90%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: rgba(17, 24, 39, 1);
  color: white;
  
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* NavBar Select CSS */

.navbar-select {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.navbar-select:hover {
  border-color: #999;
}

.navbar-select:focus {
  outline: none;
  border-color: #555;
}

.navbar-select option {
  background-color: #fff;
  color: #333;
}

.navbar-select option:hover {
  background-color: #f2f2f2;
}

.navbar-select option:checked {
  background-color: #ccc;
  color: #fff;
}

/* Add Recipe Form */

.add-recipe-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  height: 400px;
}
.add-recipe-container h2 {
  text-align: center;
}

.recipe-form {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
}

input[type="text"] {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea[id="description"] {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 200px;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Edit Recipe CSS */

.form-container {
  max-width: 700px;
  margin-left: 600px;
  margin-top: 50px;
  padding: 20px;
  height: 500px;
  background-color: #c9c5c5;
  border-radius: 10px;
}

.form-container h2 {
  font-size: 34px;
  margin-bottom: 20px;
  text-align: center;
  font-family: monospace;
}

.form-container label {
  display: block;
  margin-bottom: 5px;
}

.form-container input[type="text"],
.form-container textarea,
.form-container select {
  width: 100%; 
  padding: 8px; 
  margin-bottom: 10px;
  border: 1px solid #ccc; 
  border-radius: 5px; 
  box-sizing: border-box;
}

.form-container button[type="submit"] {
  background-color: #4CAF50; 
  color: white; 
  padding: 10px 20px; 
  border: none; 
  border-radius: 5px; 
  cursor: pointer; 
  transition: background-color 0.3s; 
}

.form-container button[type="submit"]:hover {
  background-color: #45a049; 
}

/* All Recipe container CSS  */

.recipe-container {
  margin: 20px;
}

.recipe-container h2 {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
  font-family: monospace;
  margin-top: 40px;
}

.recipe-grid {
  display: flex;
  flex-wrap: wrap; 

}

.recipe-card {
  width: calc(33.33% - 10px); 
  margin: 10px; 
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0.9, 0.7, 0.5); 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.recipe-card h3 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.recipe-card p {
  font-size: 19px;
  margin-bottom: 10px;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
}

.recipe-card button {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.recipe-card button:hover {
  background-color: #45a049;
}

.message {
  font-size: 16px;
  color: #FF0000;
  margin-top: 10px;
}

.link {
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}


/*  My Recipes CSS  */

.myrecipe-container {
  margin: 20px;
}

.myrecipe-container h2 {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
  font-family: monospace;
  margin-top: 40px;
}

.myrecipe-list {
  list-style-type: none; 
  padding: 0; 
  display: flex; 
  flex-wrap: wrap; 
}

.myrecipe-item {
  flex: 0 0 calc(33.33% - 20px); 
  background-color: #c9c5c5; 
  padding: 20px; 
  margin: 10px; 
  border-radius: 5px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.myrecipe-item h3 {
  font-size: 24px; 
  margin-bottom: 10px; 
  text-align: center;
}

.myrecipe-item p {
  font-size: 16px; 
  margin-bottom: 10px; 
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
}
.dlink {
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
  color: red;
  font-weight: bold;
  cursor: pointer;
  margin-left: 450px;
}

/* Favorites Section CSS  */

.favrecipe-container {
  margin: 20px;
}

.favrecipe-container h2 {
  font-size: 28px;
  margin-bottom: 40px;
  margin-top: 40px;
  text-align: center;
  font-family: monospace;
}

.favrecipe-list {
  list-style-type: none; 
  padding: 0; 
  display: flex; 
  flex-wrap: wrap; 
}

.favrecipe-item {
  flex: 0 0 calc(33.33% - 20px); 
  background-color: #c9c5c5; 
  padding: 20px; 
  margin: 10px; 
  border-radius: 5px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.favrecipe-item h3 {
  font-size: 24px; 
  margin-bottom: 10px; 
  text-align: center;
}

.favrecipe-item p {
  font-size: 16px; 
  margin-bottom: 10px; 
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
}

/* scrolling text animation  */
.scrolling-text {
  overflow: hidden;
  white-space: nowrap;
}

.scrolling-text:hover {
  animation-play-state: paused;
}

.scrolling-text {
  animation: marquee 25s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}


